import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.css"
import "../node_modules/bootstrap/dist/js/bootstrap.js"

import Navs from "./Components/Navbar"

import PrivateRoute from './Routes/PrivateRoute'
import PublicRoute from './Routes/PublicRoute'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
    <Router>
    <Switch>

      <PublicRoute component={App} path="/" exact />
      <PrivateRoute component={Navs} path="/dashboard" exact />
      <PrivateRoute component={Navs} path="/users" exact />
      <PrivateRoute component={Navs} path="/money_slots" exact />
      <PrivateRoute component={Navs} path="/message" exact />
      <PrivateRoute component={Navs} path="/close_days" exact />
    </Switch>
  </Router>
  </React.StrictMode>

  

  //document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();