import React from "react";
import "../App.css";

import { useState, useEffect } from "react";
import Axios from "axios";
import { Table, Card, Form } from "react-bootstrap";
import moment from "moment";


const initialState = {
  ampm: "",
  first_slot: "",
};

function MoneySlot(props) {

  const [{ ampm, first_slot }, setState] = useState(initialState);
  const [datas, setDatas] = useState([]);

  // let oncount = new Date().toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric'
  //}).split(' ').join('-'); 

  let onDate = moment().format("DD-MMM-YYYY");


  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };


  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState("");

  // const [details, setDetails] = useState({ name: "", password: "" });


  const handleSubmit = (e) => {

    e.preventDefault();
    let oncount = moment().format("DD-MMM-YYYY");
    // let oncount = new Date().toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric'
    // }).split(' ').join('-');
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {

      console.log(oncount);
      console.log(ampm);
      console.log(first_slot);



      Axios.post("https://etrade.123spider.com/api/admin/storeSlotLucky", {
        oncount,
        ampm,
        first_slot
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          Accept: "application/json"
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            Axios.get(
              `https://etrade.123spider.com/api/admin/getSlotLucky?oncount=${onDate}`,

              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => {

                setStatus("Success!");
                setValidated(false);
                // clearState();
                // setTimeout(() => {
                //   setStatus("");
                // }, 5000);

                //console.log(res.data.data)
                setDatas(res.data.data.slot_lucky);

                clearState();
                    setTimeout(() => {
                      setStatus("");
                    }, 5000);
              })

              .catch((err) => {
                console.log(err.message);
              });
          }


        })
        .catch((err) => {
          console.log(err.message)
        });
      // }

      setValidated(true);



    }

    //   function formatDate(date) {
    //     var d = new Date(date).toLocaleDateString('en-GB', {
    //       day: 'numeric',
    //       month: 'short',
    //       year: 'numeric'
    //   }).split(' ').join('-');
    //     return d;
  }






  //   let uid = sessionStorage.getItem("uid");

  //   const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  //   const [update, setUpdate] = useState(false);
  //   const [updateId, setUpdateId] = useState(null);
  //   const [deleteMessage, setDeleteMessage] = useState(null);

  //   const showDeleteModal = (id) => {
  //     setStatus("");
  //     setDeleteMessage(`Are you sure you want to delete?`);
  //     setUpdateId(id);
  //     setDisplayConfirmationModal(true);
  //   };

  //   // Hide the modal
  //   const hideConfirmationModal = () => {
  //     setDisplayConfirmationModal(false);
  //   };

  //   // Handle the actual deletion of the item
  //   const submitDelete = () => {
  //     console.log(updateId);
  //     setDisplayConfirmationModal(false);
  //     Axios.delete(
  //       `https://etrade.123spider.com/api/admin/deleteUser/`+ updateId,

  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //       .then((res) => {
  //         console.log(res.status);
  //         //setDatas(res.data.data);
  //         if(res.status === 200){
  //           Axios.get(
  //             `https://etrade.123spider.com/api/admin/allUser`,

  //             {
  //               headers: {
  //                 Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //               },
  //             }
  //           )
  //             .then((res) => {
  //               setStatus("Success")
  //               setTimeout(() => {
  //                 setStatus("");
  //               }, 5000);
  //               console.log(res.status);
  //               //setDatas(res.data.data);
  //             })

  //             .catch((err) => {
  //               console.log(err.message);
  //             });
  //         }
  //       })

  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   };



  useEffect(() => {

    //   let oncount = new Date().toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric'
    // }).split(' ').join('-');
    let onDate = moment().format("DD-MMM-YYYY");

    Axios.get(
      `https://etrade.123spider.com/api/admin/getSlotLucky?oncount=${onDate}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data.data.slot_lucky);
        setDatas(res.data.data.slot_lucky);
        //setDatas(res.data.data);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //  function handleEditClicks (id,names) {
  //         //e.preventDefault();    
  //         setStatus("");
  //         //setUpdate(true);
  //         //console.log(id);  
  //         //console.log(names); 
  //         //setUpdateId(id);
  //         setState((prevState) => ({ ...prevState, name: names }));
  //       }

  //   function handleEditCancel (){
  //     setStatus("");
  //     setUpdate(false);
  //     clearState();
  //   }  

  //   const updateData = (e)=>{
  //     console.log(updateId);
  //     e.preventDefault();
  //     if(updateId != null){
  //       Axios.put(
  //         `https://etrade.123spider.com/api/admin/updateUser/` + updateId,{
  //           name,
  //           password,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       ).then((res)=>{
  //         if(res.status === 200){
  //           Axios.get(
  //             `https://etrade.123spider.com/api/admin/allUser`,

  //             {
  //               headers: {
  //                 Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //               },
  //             }
  //           )
  //             .then((res) => {
  //               setStatus("Success!");
  //               setValidated(false);
  //               setUpdate(false);
  //               clearState();
  //               setTimeout(() => {
  //                 setStatus("");
  //               }, 5000);

  //               //setDatas(res.data.data.lucky);
  //               //console.log(res.data.data)
  //               setDatas(res.data.data);
  //             })

  //             .catch((err) => {
  //               console.log(err.message);
  //             });
  //         }

  //       }).catch((err) => {
  //         console.log(err.message)
  //       });

  //}


  return (
    <>
      {/*\\ <Navbar /> */}


      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Body>
                <h6 className="mb-4">Date {onDate}</h6>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group controlId="validationCustom02">
                    <Form.Select value={ampm}
                      aria-label="Default select example"

                      onChange={(e) =>
                      //setDetails({ ...details, selectedNo: e.target.value })
                      {
                        //const { name, value } = e.target;
                        setState((prevState) => ({ ...prevState, ampm: e.target.value }))
                      }
                      }
                    >
                      <option>Select Time</option>
                      <option value="11">11 AM</option>
                      <option value="1">1 PM</option>
                      <option value="3">3 PM</option>
                      <option value="5">5 PM</option>
                      <option value="7">7 PM</option>
                      <option value="9">9 PM</option>
                    </Form.Select>

                    <br className="mt-2"></br>
                  </Form.Group>
                  <Form.Group controlId="validationCustom03">
                    <Form.Label>Value</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="value"
                      required
                      value={first_slot}
                      name="first_slot"
                      onChange={onChange}
                    //onChange={e => setDetails({ ...details, name: e.target.value })}
                    //value={details.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Value.
                    </Form.Control.Feedback>
                  </Form.Group>


                  <br className="mt-2"></br>
                  <button type="submit" className="btn btn-primary input-block-level form-control">Add</button>


                  <br className="mt-3"></br>

                </Form>
                {status === "" ? <br className="mt-1"></br> :
                  <>
                    <br className="mt-1"></br>
                    <h6 className="mt-1 text-success">{status}</h6>
                  </>
                }

              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-9">
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, i) => (
                  <tr key={i}>
                    <td>{data.oncount}</td>
                    {
                      data.ampm === "11" ? <td>{data.ampm} AM</td> : <td>{data.ampm} PM</td>
                    }
                    {/* <td>{data.ampm}</td> */}
                    <td>{data.first_slot}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {/* <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} message={deleteMessage}  />
     */}

    </>
  );
}

export default MoneySlot;
