// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBMYwnh4Ac92D9_eE-J5dP1SWPtyAh-dfY",
  authDomain: "mm-2-45e01.firebaseapp.com",
  projectId: "mm-2-45e01",
  storageBucket: "mm-2-45e01.appspot.com",
  messagingSenderId: "803021432004",
  appId: "1:803021432004:web:e1b6409dda90dbe0da9591"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

